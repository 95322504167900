import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18next from "i18next";

export function createUser(user) {
    const tmp = {
        CogAccData: {
            email: user.email,
            accName: user.userLogin,
            phoneNumber: user.phone,
            uName: user.userName,
            address: user.address
        },
        UserAccData: {
            roles: user.roles || [],
            maxDevices: 1,
            // maxDevicesPC: 1,
            // maxDevicesMobile: 1,
            maxConcurrentSessions: 1
        }
    };

    return invokeApig({
        ...Paths.createUser(user.userType, user.defaultFarm),
        body: tmp
    });
}

export function createUserNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("success"),
        message: i18next.t("popNotifications.added"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createUserNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("error"),
        message: i18next.t("popNotifications.additionErr"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
