import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18next from "i18next";

export function createAlias({ClientID, Alias, LocalUserID}) {
    const tmp = {
        ClientID,
        Alias
    };
    return invokeApig({
        ...Paths.changeClientAlias({localUserID: LocalUserID}),
        queryParams: tmp
    });
}

export function createAliasSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("success"),
        message: i18next.t("success"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function createAliasFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("error"),
        message: i18next.t("error"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
