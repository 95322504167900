import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18next from "i18next";

export function editSubscription(addOns, farmID, clientId, subscriptionId) {
    return invokeApig({
        ...Paths.updateSubscription({clientId, farmID}),
        queryParams: {subscriptionId},
        body: {addOns}
    })
}

export function editSubscriptionNotificationSuccess(response, uniqueId = myID()) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("success"),
        message: i18next.t("popNotifications.subscribeUpdateOk"),
        status: "success",
        dismissible: true,
        dismissAfter: 5000
    }));
}

export function editSubscriptionNotificationFailure(response, uniqueId = myID()) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("error"),
        message: i18next.t("popNotifications.subscribeUpdateErr"),
        status: "error",
        dismissible: true,
        dismissAfter: 15000
    }))
}
