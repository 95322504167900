import {invokeApig} from "../../libs/awsLib";
import store from "../../store/store";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import {addNotification} from "reapop";
import i18next from "i18next";

export async function createSubscription(planId, dropinInstance, merchantAccountId, receivedFarmObject, sub, trialInfo, addons, balance) {
    const paymentMethod = await dropinInstance.requestPaymentMethod();
    const {user: {user: {ClientID}}, location: {farm}} = store.getState();
    const farmID = !receivedFarmObject ? ClientID : receivedFarmObject.FarmID;
    const subID = farmID + myID();
    const farmObject = !receivedFarmObject || !farm ? {
        ...{
            Adr: "Augustowo 6, 86-022 Dobrcz",
            FarmCord: "53.228928, 18.095559",
            FarmID: farmID,
            FarmName: "Farm " + farmID
        }, ...receivedFarmObject
    } : {...receivedFarmObject};
    return invokeApig({
        ...Paths.createSubscription({clientID: ClientID, farmID}),
        body: {
            sub: {
                id: subID,
                planId,
                paymentMethodNonce: paymentMethod.nonce,
                merchantAccountId,
                ...trialInfo,
                addOns: addons
            },
            farm: {
                farmObject
            },
            trans: {
                balance
            }
        }
    })
}

export function createSubscriptionNotificationSuccess(response, uniqueId = myID()) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("success"),
        message: i18next.t("popNotifications.subscribeOk"),
        status: "success",
        dismissible: true,
        dismissAfter: 5000
    }));
}

export function createSubscriptionNotificationFailure(response, uniqueId = myID()) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("error"),
        message: i18next.t("popNotifications.subscribeErr"),
        status: "error",
        dismissible: true,
        dismissAfter: 15000
    }));
}

