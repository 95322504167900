import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n";

export function validateAddons(data, {FarmID, ClientID} = {}) {
    return invokeApig({
        ...Paths.validateAddons({clientID: ClientID}),
        queryParams: {FarmID},
        body: data
    })
}

export function validateAddonsFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("apiNotifications.validateAddonsTitle"),
        message: i18n.t("apiNotifications.validateAddonsFailure"),
        status: "error",
        dismissible: true,
        dismissAfter: 0
    }))
}
