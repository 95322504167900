import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";

export function getTasks(DtaPlanned, {FarmID, ClientID, LocalUserID} = {}) {
    const {_FarmID, _LocalUserID, _ClientID} = getUserApiData({FarmID, ClientID, LocalUserID});
    return invokeApig({
        ...Paths.getTasks({farmID: _FarmID, clientID: _ClientID, localUserID: _LocalUserID}),
        queryParams: {DtaPlanned}
    });
}