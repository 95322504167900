import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {ns} from "../../i18n"

/**
 * Pobiera tłumaczenia zapisane na S3
 * @param languages {Array<string>}     tablica języków do pobrania
 * @param LocalUserID {string}
 * @return {Promise<any|undefined>}
 */
export function getTranslations(languages, {LocalUserID} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.getTranslations({localUserID: _LocalUserID}),
        queryParams: {
            lang: languages,
            ns: languages.length !== 0 ? ns : []
        }
    })
}