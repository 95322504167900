import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n"

export function deleteReport(LocalQTime, {ClientID, LocalUserID} = {}) {
    const {_LocalUserID, _ClientID} = getUserApiData({ClientID, LocalUserID});
    return invokeApig({
        ...Paths.deleteReport({clientID: _ClientID, localUserID: _LocalUserID}),
        body: {
            LocalQTime
        }
    })
}

export function deleteReportNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("deleteReport"),
        message: i18n.t("apiNotifications.deleteReportSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function deleteReportNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("deleteReport"),
        message: i18n.t("apiNotifications.deleteReportFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}