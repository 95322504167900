import store from "../store/store";

export default class Paths {

    static createBuilding({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/building/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        };
    }

    static listBuilding({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general/building/${farmID || farm}`,
            method: "GET"
        };
    }

    static updateBuilding({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        //console.log(farmID, clientID, localUserID, farm, LocalUserID, ClientID, 'PUT');
        return {
            path: `general/building/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        };
    }

    static createAnimal({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/animal/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        };
    }

    static listAnimal({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general/animal/${farmID || farm}`,
            method: "GET"
        };
    }

    static updateAnimal({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/animal/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        };
    }

    static createEvent({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/events/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        };
    }

    static sendEmail() {
        return {
            path: `general2/service/mailfwd`,
            method: "POST"
        };
    }

    static listEvent({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general/events/${farmID || farm}`,
            method: "GET"
        };
    }

    static listEventsForAnimals() {
        return {
            path: `general/eventsForAnimals/`,
            method: "POST"
        };
    }

    static updateEvent({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/events/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        };
    }

    static createDevices({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `devices/device/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        };
    }

    static listDevices({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `devices/device/${farmID || farm}`,
            method: "GET"
        };
    }

    static updateDevices({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `devices/device/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        };
    }

    static createTechnologyGroups({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/tg/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        };
    }

    static listTechnologyGroups({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general/tg/${farmID || farm}`,
            method: "GET"
        };
    }

    static updateTechnologyGroups({farmID, clientID, localUserID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/tg/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        };
    }

    static createDictionaries({clientID, localUserID, farmID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/dictionary/${clientID || ClientID}/${localUserID || LocalUserID}/${farmID || farm}`,
            method: "POST"
        };
    }

    static listDictionaries({clientID} = {}) {
        const {user: {user: {ClientID}}} = store.getState();
        return {
            path: `general/dictionary/${clientID || ClientID}`,
            method: "GET"
        };
    }

    static updateDictionaries({clientID, localUserID, farmID} = {}) {
        const {user: {user: {LocalUserID, ClientID}}, location: {farm}} = store.getState();
        return {
            path: `general/dictionary/${clientID || ClientID}/${localUserID || LocalUserID}/${farmID || farm}`,
            method: "PUT"
        };
    }

    static getAggregatedData(DevID, {farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general/aggData/${farmID || farm}/${DevID}`,
            method: "GET"
        };
    }

    static getUser(UserID) {
        if (!UserID) throw new Error('getUser: UserID is mandatory');
        return {
            path: `userapi/user/${UserID}`,
            method: "GET"
        };
    }

    static listUser({clientID} = {}) {
        const {user: {user: {ClientID}}} = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}`,
            method: "GET"
        };
    }

    static createUser(accType = {accType: "F"}, defaultFarm) {
        const {user: {user: {ClientID}}} = store.getState();
        console.log("createUser type", accType);
        return {
            path: `userapi/account/${accType}/${ClientID}/${defaultFarm}`,
            method: "POST"
        };
    }

    static verifyUserEmail() {
        return {
            path: "general2/service/verifymail",
            method: "POST"
        }
    }

    static enableDisableUser({clientID} = {}) {
        const {user: {user: {ClientID}}} = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}`,
            method: "PUT"
        };
    }

    static updateUser({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        };
    }

    static deleteUser({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `userapi/users/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "DELETE"
        }
    }

    static changeMQTTPassword({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `userapi/userMqttPasswd/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    //nowe nie zateirdzone nizej

    static changeClientAlias({localUserID} = {}) {
        const {user: {user: {LocalUserID}}} = store.getState();
        return {
            path: `userapi/clients/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    static createSetting({clientID, localUserID, farmID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `general2/settings/${clientID || ClientID}/${localUserID || LocalUserID}/${farmID || farm}`,
            method: "POST",
        };
    }

    static updateSetting({clientID, localUserID, farmID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `general2/settings/${clientID || ClientID}/${localUserID || LocalUserID}/${farmID || farm}`,
            method: "PUT",
        };
    }

    static listSettings({clientID} = {}) {
        const {user: {user: {ClientID}}} = store.getState();
        return {
            path: `general2/settings/${clientID || ClientID}`, ///${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static service() {
        const {user: {user: {LocalUserID}}} = store.getState();
        return {
            path: `userapi/service/cc/${LocalUserID}`,
            method: "PUT"
        }
    }

    static listNotifications({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general2/notificationsFarm/${farmID || farm}`, ///${localUserID || LocalUserID}`,
            method: "GET",
        };
    }

    static updateNotifications({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/notifications/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT",
        }
    }

    static removeNotification({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/notifications/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "DELETE",
        }
    }

    static createNotifications({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/notifications/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST",
        }
    }

    static listDocuments() {
        return {
            path: `general2/documents`,
            method: "GET",
        }
    }

    static updateDocuments({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/documents/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    static markDocumentAsRead({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `userapi/userDocRead/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    static listGroups({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general/animalGR/${farmID || farm}`,
            method: "GET"
        }
    }

    static createGroup({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general/animalGR/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static updateGroup({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general/animalGR/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    static listLogs({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general2/logs/${farmID || farm}`,
            method: "GET"
        }
    }

    static getLogObjectByID({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/logs/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "GET"
        }
    }

    static listCommandsHistory({farmID, /*localUserID*/} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general2/commandsHistory/${farmID || farm}`, //${localUserID || LocalUserID}`,
            method: "GET"
        }
    }

    static retryPayment() {
        return {
            path: 'billing/retry',
            method: "POST"
        }
    }

    static terminateAccount({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `billing/terminate/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static commandProxy({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `iot/iotCommands/${farmID || farm}`,
            method: "POST"
        }
    }

    static registerIOT() {
        return {
            path: `iot/register`,
            method: "GET"
        }
    }

    /*
    ######################################
    #  SNS
    ######################################
    */
    static registerSNSEndpoint() {
        return {
            path: `general2/sns/register`,
            method: "POST"
        }
    }

    static subscribeSNSTopic() {
        return {
            path: `general2/sns/subscribe`,
            method: "POST"
        }
    }

    static publishSNSendpoint() {
        return {
            path: `general2/sns/publish`,
            method: "POST"
        }
    }

    static unsubscribeSNSTopic() {
        return {
            path: `general2/sns/unsubscribe`,
            method: "POST"
        }
    }

    /*
    ######################################
    #  BILLING
    ######################################
    */
    static getAddons() {
        return {
            path: `billing/addons`,
            method: "GET"
        }
    }

    static validateAddons({clientID} = {}) {
        const {user: {user: {ClientID}}} = store.getState();
        return {
            path: `billing/addons/${clientID || ClientID}`,
            method: "POST"
        }
    }

    static getPlans() {
        return {
            path: `billing/plans`,
            method: "GET"
        }
    }

    static generateToken({customerId} = {}) {
        const {user: {attributes: {sub}}} = store.getState();
        return {
            path: `billing/token/${customerId || sub}`,
            method: "GET"
        };
    }

    static createSubscription({clientID, farmID} = {}) {
        const {user: {user: {ClientID}}, location: {farm}} = store.getState();
        return {
            path: `billing/subscription/${clientID || ClientID}/${farmID || farm}`,
            method: "POST"
        };
    }

    static updateSubscription({clientId, farmID} = {}) {
        const {user: {user: {ClientID}}, location: {farm}} = store.getState();
        return {
            path: `billing/subscription/${clientId || ClientID}/${farmID || farm}`,
            method: "PUT"
        }
    }

    static searchSubscription() {
        return {
            path: `billing/subscription`,
            method: "GET"
        };
    }

    static createPaymentMethod({customerId} = {}) {
        const {user: {attributes: {sub}}} = store.getState();
        return {
            path: `billing/paymentMethod/${customerId || sub}`,
            method: "POST"
        };
    }

    static updatePaymentMethod({customerId} = {}) {
        const {user: {attributes: {sub}}} = store.getState();
        return {
            path: `billing/paymentMethod/${customerId || sub}`,
            method: "PUT"
        };
    }

    static deletePaymentMethod() {
        return {
            path: `billing/paymentMethod`,
            method: "DELETE"
        };
    }

    static cancelSubscription() {
        return {
            path: `billing/subscription`,
            method: "DELETE"
        };
    }

    static customerCreate({customerId} = {}) {
        const {user: {attributes: {sub}}} = store.getState();
        return {
            path: `billing/customer/${customerId || sub}`,
            method: "POST"
        };
    }

    static customerFind({customerId} = {}) {
        const {user: {attributes: {sub}}} = store.getState();
        return {
            path: `billing/customer/${customerId || sub}`,
            method: "GET"
        };
    }

    static customerDelete({customerId} = {}) {
        const {user: {attributes: {sub}}} = store.getState();
        return {
            path: `billing/customer/${customerId || sub}`,
            method: "DELETE"
        };
    }

    static transactionHistory({customerId} = {}) {
        const {user: {attributes: {sub}}} = store.getState();
        return {
            path: `billing/transaction/${customerId || sub}`,
            method: "GET"
        };
    }

    static deviceParam({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `devices/deviceParam/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    /*
    ######################################
    #  REPORTS
    ######################################
    */

    static getReports({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/getReport/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "GET"
        }
    }

    static createReport({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/report/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static getReportData({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/getReport/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    /*
    ######################################
    #  EVENTS
    ######################################
    */

    static createTransfer({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/transfer/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static listSettlements({farmID} = {}) {
        const {location: {farm}} = store.getState();
        return {
            path: `general2/fti/${farmID || farm}`,
            method: "GET"
        }
    }

    static createSettlement({farmID, clientID} = {}) {
        const {location: {farm}, user: {user: {ClientID}}} = store.getState();
        return {
            path: `general2/fti/${farmID || farm}/${clientID || ClientID}`,
            method: "PUT"
        }
    }

    static getTranslations({localUserID} = {}) {
        const {user: {user: {LocalUserID}}} = store.getState();
        return {
            path: `general2/translations/${localUserID || LocalUserID}`,
            method: "GET"
        }
    }

    static updateTranslations({localUserID} = {}) {
        const {user: {user: {LocalUserID}}} = store.getState();
        return {
            path: `general2/translations/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    static deleteTranslations({localUserID} = {}) {
        const {user: {user: {LocalUserID}}} = store.getState();
        return {
            path: `general2/translations/${localUserID || LocalUserID}`,
            method: "DELETE"
        }
    }

    /*
    ######################################
    #  EVENTS
    ######################################
    */

    static createInsemination({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/insemination/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createUSG({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/usg/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createNoPregnancy({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/noPregnancy/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createParturition({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/parturition/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createSeparationToMommy({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/separationToMommy/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createMommy({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/mommy/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createFallPiglets({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/fallPiglets/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createFall({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/fall/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createSelection({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/selection/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createWeighting({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/weighting/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createActiveNipples({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/activeNipples/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createSeparation({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/separation/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createSeparationByAnimal({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/separation/animal/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createSell({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/sell/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createHeat({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/heat/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createGrafting({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/grafting/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static endGrafting({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/endGrafting/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createPigletsTreatment({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/pigletsTreatment/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createCastration({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/castration/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createToInsemination({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/toInsemination/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createToRepeatInsemination({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/toRepeat/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static startParturition({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/startParturition/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static endParturition({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/endParturition/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createReclassify({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/reclassify/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createTreatment({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events/treatment/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createInsertion({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `events2/insertion/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    /*
    ######################################
    #  SERVICE
    ######################################
    */

    static getMaintenance() {
        return {
            path: `general2/service/maintenance`,
            method: "GET"
        };
    }

    static deleteFarmData({farmID}) {
        return {
            path: `develop/farms/${farmID}`,
            method: "POST"
        }
    }

    /*
    ######################################
    #  REPORTS
    ######################################
    */

    static deleteReport({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `general2/reports/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "DELETE"
        }
    }

    /*
    ######################################
    #  DEVICES
    ######################################
    */

    static assignDeviceToFarm({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `devices/assign/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static createSettlementForDevice({farmID, clientID, localUserID} = {}) {
        const {location: {farm}, user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `devices/settlement/start/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static endSettlementForDevice({farmID, clientID} = {}) {
        const {location: {farm}, user: {user: {ClientID}}} = store.getState();
        return {
            path: `devices/settlement/end/${farmID || farm}/${clientID || ClientID}`,
            method: "POST"
        }
    }

    static setFeedingEfficiency({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `devices/efficiency/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static setWaterEfficiency({farmID, clientID, localUserID} = {}) {
        const {user: {ClientID, LocalUserID}, location: {farm}} = store.getState();
        return {
            path: `devices/water/efficiency/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static addNecessaryDevices({farmID, clientID, localUserID} = {}) {
        const {user: {ClientID, LocalUserID}, location: {farm}} = store.getState();
        return {
            path: `devices/fix/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    /*
    ######################################
    #  DEBUG
    ######################################
    */

    static debugClientEnv() {
        return {
            path: `develop/debugClientEnv`,
            method: "POST"
        }
    }

    static changeRFID({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `general/animal/rfid/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    static editAnimal({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `general/editAnimal/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT",
        };
    }

    /*
    ######################################
    #  SERVICE
    ######################################
     */

    static setMaintenanceDate({localUserID} = {}) {
        const {user: {user: {LocalUserID}}} = store.getState();
        return {
            path: `develop/maintenance/${localUserID || LocalUserID}`,
            method: "POST",
        };
    }

    /*
    ######################################
    #  BREEDING
    ######################################
    */

    static editSowCycle({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `breeding/cycle/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    /*
 ######################################
 #  USER
 ######################################
 */

    static getAllUsers({clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}} = store.getState();
        return {
            path: `userapi/allUsers/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "GET"
        }
    }

    /*
    ######################################
    #  TASK
    ######################################
    */

    static createTask({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "POST"
        }
    }

    static getTasks({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "GET"
        }
    }

    static editTask({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    static changeTaskStatus({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `task/status/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }

    static removeTask({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `task/task/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "DELETE"
        }
    }

    static changeSubtaskStatus({farmID, clientID, localUserID} = {}) {
        const {user: {user: {ClientID, LocalUserID}}, location: {farm}} = store.getState();
        return {
            path: `task/subtask/${farmID || farm}/${clientID || ClientID}/${localUserID || LocalUserID}`,
            method: "PUT"
        }
    }
}
