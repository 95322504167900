import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n";

export function deletePaymentMethod(paymentMethodToken) {
    return invokeApig({
        ...Paths.deletePaymentMethod(),
        queryParams: {Token: paymentMethodToken}
    })
}

export function deletePaymentMethodNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("success"),
        message: i18n.t("popNotifications.paymentRemoveOk"),
        status: "success",
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function deletePaymentMethodNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("error"),
        message: i18n.t("popNotifications.paymentRemoveErr"),
        status: "error",
        dismissible: true,
        dismissAfter: 15000
    }))
}
