import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import {myID} from "../../libs/generateID";
import store from "../../store/store";
import {addNotification} from "reapop";
import i18n from "../../i18n";

export function cancelSubscription(subscriptionId) {
    return invokeApig({
        ...Paths.cancelSubscription(),
        queryParams: {SubscriptionID: subscriptionId}
    })
}

export function cancelSubscriptionNotificatonSuccess(response, {uniqueId = myID()} = {}) {
    const {id} = response.subscription;
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("success"),
        message: i18n.t("popNotifications.subscribeRemoveOk") + id,
        status: "success",
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function cancelSubscriptionNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18n.t("error"),
        message: i18n.t("popNotifications.subscribeRemoveErr"),
        status: "error",
        dismissible: true,
        dismissAfter: 15000
    }))
}
