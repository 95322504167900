import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import store from "../../store/store";
import {addNotification} from "reapop";
import {myID} from "../../libs/generateID";
import i18next from "i18next";

/**
 * Aktualizuje tlumaczenia na S3
 * @param langs {[{lang: string, path: string, value: any, accepted: bool}]}                obiekt z jezykami
 * @param LocalUserID {string}
 * @return {Promise<any|undefined>}
 */
export function updateTranslations(langs, {LocalUserID} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.updateTranslations({localUserID: _LocalUserID}),
        body: langs
    })
}

export function updateTranslationsNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("apiNotifications.updateTranslationTitle"),
        message: i18next.t("apiNotifications.updateTranslationSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function addTranslationsNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("apiNotifications.addTranslationTitle"),
        message: i18next.t("apiNotifications.addTranslationSuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function updateTranslationsNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("apiNotifications.updateTranslationTitle"),
        message: i18next.t("apiNotifications.updateTranslationFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}

export function addTranslationsNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("apiNotifications.addTranslationTitle"),
        message: i18next.t("apiNotifications.addTranslationFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
