import {getUserApiData} from "../../utils/UserUtils";
import {invokeApig} from "../../libs/awsLib";
import Paths from "../paths";
import store from "../../store/store";
import {addNotification} from "reapop";
import {myID} from "../../libs/generateID";
import i18next from "i18next";

/**
 * Aktualizuje tlumaczenia na S3
 * @param langs {[{lang: string, path: string}]}    obiekt z jezykami
 * @param LocalUserID {string}
 * @return {Promise<any|undefined>}
 */
export function deleteTranslations(langs, {LocalUserID} = {}) {
    const {_LocalUserID} = getUserApiData({LocalUserID});
    return invokeApig({
        ...Paths.deleteTranslations({localUserID: _LocalUserID}),
        body: langs
    })
}

export function deleteTranslationsNotificationSuccess(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("apiNotifications.deleteTranslationTitle"),
        message: i18next.t("apiNotifications.deleteTranslationsuccess"),
        status: 'success',
        dismissible: true,
        dismissAfter: 5000
    }))
}

export function deleteTranslationsNotificationFailure(response, {uniqueId = myID()} = {}) {
    store.dispatch(addNotification({
        id: uniqueId,
        title: i18next.t("apiNotifications.deleteTranslationTitle"),
        message: i18next.t("apiNotifications.deleteTranslationFailure"),
        status: 'error',
        dismissible: true,
        dismissAfter: 15000
    }))
}
